<template>
  <exception-page type="404" :show-home-button="!isMenuView" />
</template>

<script>
import ExceptionPage from '@/components/Exception'

export default {
  components: {
    ExceptionPage,
  },
  computed: {
    isMenuView() {
      return this.$route.path.startsWith('/m')
    },
  },
}
</script>

<style scoped>
</style>
